export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAIL = "GET_POSTS_FAIL";
export const CONTACT_DETAILS = "CONTACT_DETAILS";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAIL = "CONTACT_FAIL";
export const SIGN_UP_POST = "SIGN_UP_POST";
export const MOBILE_NUMBER_SUCCESS = "MOBILE_NUMBER_SUCCESS";
export const MOBILE_NUMBER_ERROR = "MOBILE_NUMBER_ERROR";
export const VERIFY_OTP_DATA = "VERIFY_OTP_DATA";
export const VERIFY_OTP_SUCCESS = "VERIFY_MOBILE_NUMBER_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_MOBILE_NUMBER_ERROR";
export const OTP_VALUE = "OTP_VALUE";
export const POST_LOGINDETAIL = "POST_LOGIN_DETAIL";
export const POST_OTPDETAIL = "POST_OTP_DETAIL";
export const POST_LOGINDETAIL_SUCCESS = "POST_LOGINDETAIL_SUCCESS";
export const POST_LOGINDETAIL_ERROR = "POST_LOGINDETAIL_ERROR";
export const POST_OTPDETAIL_SUCCESS = "POST_OTPDETAIL_SUCCESS";
export const POST_OTPDETAIL_ERROR = "POST_OTPDETAIL_ERROR";
export const GET_BASICDETAIL = "GET_BASICDETAIL";
export const GET_BASICDETAIL_SUCCESS = "GET_BASICDETAIL_SUCCESS";
export const GET_BASICDETAIL_ERROR = "GET_BASICDETAIL_ERROR";
export const POST_BASICDETAIL = "POST_BASICDETAIL";
export const POST_BASICDETAIL_SUCCESS = "POST_BASICDETAIL_SUCCESS";
export const POST_BASICDETAIL_ERROR = "POST_BASICDETAIL_ERROR";
export const GET_ADDRESSDETAIL = "GET_ADDRESSDETAIL";
export const GET_ADDRESSDETAIL_SUCCESS = "GET_ADDRESSDETAIL_SUCCESS";
export const GET_ADDRESSDETAIL_ERROR = "GET_ADDRESSDETAIL_ERROR";
export const POST_ADDRESSDETAIL = "POST_ADDRESSDETAIL";
export const POST_ADDRESSDETAIL_SUCCESS = "POST_ADDRESSDETAIL_SUCCESS";
export const POST_ADDRESSDETAIL_ERROR = "POST_ADDRESSDETAIL_ERROR";
export const GET_COMMONDETAIL = "GET_COMMONDETAIL";
export const GET_COMMONDETAIL_SUCCESS = "GET_COMMONDETAIL_SUCCESS";
export const GET_COMMONDETAIL_ERROR = "GET_COMMONDETAIL_ERROR";
export const GET_COMMONDETAIL_CATCH_ERROR = "GET_COMMONDETAIL_CATCH_ERROR";
export const POST_COMMONDETAIL = "POST_COMMONDETAIL";
export const POST_COMMONDETAIL_SUCCESS = "POST_COMMONDETAIL_SUCCESS";
export const POST_COMMONDETAIL_ERROR = "POST_COMMONDETAIL_ERROR";
export const SEND_OTP = "SEND_OTP";
export const DEAL_OFFER = "https://www.stashfin.com/deal-and-offer";
export const DEAL_WOMEN_CARD = "/women-card";
export const LOAN_CALCULATOR = "/loan-calculator";
export const LOGIN_MAKE_PAYMENT = "/login";
export const ABOUT_US = "/about-us";
export const GRIEVANCE_REDRESSAL_MECHANISM = "/grievance-redressal-mechanism";
export const TERM_CONDITIONS = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";
export const PRIVACY_POLICY_AKARA = "/privacy-policy-akara";
export const CONTACT_US = "/contact-us";
export const CAREERS = "/careers";
export const NEWS_FOOTER = "/news";
export const PARTNER_LINK = "/partners";
export const CUSTOMER_SERVICE = "/customer-services";
export const DEAL_AND_OFFER = "DEAL_AND_OFFER";
export const DEAL_AND_OFFER_SUCCESS = "DEAL_AND_OFFER_SUCCESS";
export const DEAL_AND_OFFER_ERROR = "DEAL_AND_OFFER_ERROR";
export const CUSTOMER_SERVICE_SEARCH = "CUSTOMER_SERVICE_SEARCH";
export const CUSTOMER_SERVICE_SEARCH_LIST = "CUSTOMER_SERVICE_SEARCH_LIST";
export const CUSTOMER_SERVICE_RAISE_TICKET_INPUT = "CUSTOMER_SERVICE_RAISE_TICKET_INPUT";
export const CUSTOMER_SERVICE_RAISE_TICKET_INPUT_NULL = "CUSTOMER_SERVICE_RAISE_TICKET_INPUT_NULL";
export const SEND_EVENT_TO_CLEVERTAP = "SEND_EVENT_TO_CLEVERTAP";

export const REACT_APP_CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const REACT_APP_MENULINK = process.env.REACT_APP_REDIRECT_LINK_LIVE;
export const FOUNDER_LINK = '/founders';
export const ARMY_LINK = '/sentinel';
export const SITE_MAP = '/sitemap';
export const NEWS_ROOM_LINK = '/newsroom';
export const INSURANCE = '/insurance';
export const REFERRAL_PROGRAM = '/refer-and-earn';
export const HOME_LINK = '/';
export const CUSTOMER_SERVICES = 'CUSTOMER_SERVICES';
export const CUSTOMER_SERVICES_SUCCESS = 'CUSTOMER_SERVICES_SUCCESS';
export const CUSTOMER_SERVICES_ERROR = 'CUSTOMER_SERVICES_ERROR';
export const CREATE_TICKET_SUBMIT_FORM = 'CREATE_TICKET_SUBMIT_FORM';
export const CREATE_TICKET_SUBMIT_FORM_ADDITIONAL = 'CREATE_TICKET_SUBMIT_FORM_ADDITIONAL';
export const CREATE_TICKET_SUBMIT_FORM_SUCCESS = 'CREATE_TICKET_SUBMIT_FORM_SUCCESS';
export const CREATE_TICKET_SUBMIT_FORM_ERROR = 'CREATE_TICKET_SUBMIT_FORM_ERROR';
export const CLEAR_TICKET_ERROR = 'CLEAR_TICKET_ERROR';
export const SAVE_COMMENT = 'SAVE_COMMENT';
export const SAVE_COMMENT_SUCCESS = 'SAVE_COMMENT_SUCCESS';
export const SAVE_COMMENT_FAIL = 'SAVE_COMMENT_FAIL';
export const SEND_OTP_ACTION = 'SEND_OTP';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCEESS';
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR';
export const VARIFY_OTP_ACTION = 'VARIFY_OTP';
export const VARIFY_OTP_SUCCESS = 'VARIFY_OTP_SUCEESS';
export const VARIFY_OTP_ERROR = 'VARIFY_OTP_ERROR';
export const CHECK_CIBIL_REPORT = 'https://creditreport.stashfin.com/';

//URL parameters
export const GET_POSTS_URL_PARAM = '/onboarding/save-form';
export const GET_OTP_POSTS_URL_PARAM = 'onboarding/send-otp';
export const GET_VERIFY_OTP_POSTS_URL_PARAM = '/onboarding/verify-otp';
export const GET_OTP_PARAMS = 'onboarding/send-customer-otp';
export const VARIFY_OTP_PARAMS = 'onboarding/verify-customer-otp';
// export const GET_BASICDETAIL_URL_PARAM = "/sdui/view-basic-detail"
export const GET_BASICDETAIL_URL_PARAM = "/onboarding/get-product-components";
export const GET_BANKING_PARAMS = "BankStatementApi/index";
export const POST_BASICDETAIL_URL_PARAM = window.localStorage.getItem("api");
export const GET_COMMONDETAIL_URL_PARAM = "/onboarding/get-common-components";
export const POST_COMMONDETAIL_URL_PARAM = window.localStorage.getItem("api");
export const POST_LOGINDETAIL_URL_PARAMS = "onboarding/login";
// export const POST_BASICDETAIL_URL_PARAM = "onboarding/bank-details?crn=589921731"

// onboarding/bank-details
export const GET_VERIFY_CAPTCHA_URL_PARAM = "/onboarding/verify-captcha?token=";
export const GET_POSTS_DEAL_AND_OFFER_URL_PARAM = "/alloffer";
export const RESCHEDULE_TICKET_URL_PARAM = "ticket/reschedule-ticket/";

export const COMMENT = 'ticket/save-ticket-comment/';
export const GET_CUSTOMER_SERVICE_PARAM = '/ticket/get-contact-us/';
export const POST_CUSTOMER_SERVICE_SUBMIT_FORM_PARAM = 'ticket/create-ticket/';
export const WHY_US_NEW = '/why-us';
export const INSTANT_PERSONAL_LOANS = '/instant-personal-loans';
export const CITY_WISE_INSTANT_PERSONAL_LOANS = '/instant-personal-loans-in';
export const SECRET_WEB_KEY = '6aeb53996e29f845ae6b52dbda150df5fb03b981da8eaac63e3faa327fdce343';
export const INVESTOR_AND_MEDIA_RELATIONS = 'https://forms.gle/tewWSvjsVPhUsqJDA';
// Contant data for dropdown
export const INCOME = ["1000-2000", "2000-4000", "4000-20000", "20000-50000", "More than 50000"];
export const GENDER = ["Male", "Female", "Other"];
