export const SET_CURRENT_SCREEN = "SET_CURRENT_SCREEN";
export const SET_BOND_DETAILS = "SET_BOND_DETAILS";
export const SET_ORDERS = "SET_ORDERS";
export const SET_STASHFIN_APPROVE_STATUS = "SET_STASHFIN_APPROVE_STATUS";
export const SET_REGISTERED_ON_GRIP = "SET_REGISTERED_ON_GRIP";
export const SET_REGISTERED_ON_YUBI = "SET_REGISTERED_ON_YUBI";

export const GET_USER_CONSENT_STATUS = "GET_USER_CONSENT_STATUS";
export const GET_USER_CONSENT_STATUS_SUCCESS = "GET_USER_CONSENT_STATUS_SUCCESS";
export const GET_USER_CONSENT_STATUS_FAILURE = "GET_USER_CONSENT_STATUS_FAILURE";

export const REGISTER_USER_TO_GRIP = "REGISTER_USER_TO_GRIP";
export const REGISTER_USER_TO_GRIP_SUCCESS = "REGISTER_USER_TO_GRIP_SUCCESS";
export const REGISTER_USER_TO_GRIP_FAILURE = "REGISTER_USER_TO_GRIP_FAILURE";

export const GET_ALL_GRIP_BONDS = "GET_ALL_GRIP_BONDS";
export const GET_ALL_GRIP_BONDS_SUCCESS = "GET_ALL_GRIP_BONDS_SUCCESS";
export const GET_ALL_GRIP_BONDS_FAILURE = "GET_ALL_GRIP_BONDS_FAILURE";

export const GET_BOND_DETAILS = "GET_BOND_DETAILS";
export const GET_BOND_DETAILS_SUCCESS = "GET_BOND_DETAILS_SUCCESS";
export const GET_BOND_DETAILS_FAILURE = "GET_BOND_DETAILS_FAILURE";

export const GET_GRIP_REDIRECTION_LINK = "GET_GRIP_REDIRECTION_LINK";
export const GET_GRIP_REDIRECTION_LINK_SUCCESS = "GET_GRIP_REDIRECTION_LINK_SUCCESS";
export const GET_GRIP_REDIRECTION_LINK_FAILURE = "GET_GRIP_REDIRECTION_LINK_FAILURE";

export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const REDEEM_NOW = "REDEEM_NOW";
export const REDEEM_NOW_SUCCESS = "REDEEM_NOW_SUCCESS";
export const REDEEM_NOW_FAILURE = "REDEEM_NOW_FAILURE";

export const RESET_REDEEM = "RESET_REDEEM";

export const YUBI_REDIRECT = "YUBI_REDIRECT";
export const YUBI_REDIRECT_SUCCESS = "YUBI_REDIRECT_SUCCESS";
export const YUBI_REDIRECT_FAILURE = "YUBI_REDIRECT_FAILURE";
export const IS_BACK = "IS_BACK";

export const SET_USER_BASIC_DETAILS = "SET_USER_BASIC_DETAILS";

export const GET_WEALTH_WAITLIST = "GET_WEALTH_WAITLIST";
export const GET_WEALTH_WAITLIST_SUCCESS = "GET_WEALTH_WAITLIST_SUCCESS";
export const GET_WEALTH_WAITLIST_FAILURE = "GET_WEALTH_WAITLIST_FAILURE";

export const ADD_WEALTH_WAITLIST = "ADD_WEALTH_WAITLIST";
export const ADD_WEALTH_WAITLIST_SUCCESS = "ADD_WEALTH_WAITLIST_SUCCESS";
export const ADD_WEALTH_WAITLIST_FAILURE = "ADD_WEALTH_WAITLIST_FAILURE";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const PRIORITY_LIST_FORM_RESPONSES = "PRIORITY_LIST_FORM_RESPONSES";

export const PRE_CONFIG_SWITCH = "PRE_CONFIG_SWITCH";
export const PRE_CONFIG_SWITCH_SUCCESS = "PRE_CONFIG_SWITCH_SUCCESS";
export const PRE_CONFIG_SWITCH_FAILURE = "PRE_CONFIG_SWITCH_FAILURE";

export const SET_VERIFY_ACCOUNT_PAGE_APPEARANCE_COUNT = "SET_VERIFY_ACCOUNT_PAGE_APPEARANCE_COUNT";

export const VERIFY_ACCOUNT_PAGE_SHOWN = "VERIFY_ACCOUNT_PAGE_SHOWN";
